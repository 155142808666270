import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import mainStore from "store/store";
import { trackUpsellAccepted, trackPageViewEvent } from "mixpanel/events";
import ClipLoader from "react-spinners/ClipLoader";
import PurchaseStore from "store/purchase";
import { calculateTax } from "utils/global";

function UpSell1B() {
  const navigate = useNavigate();
  const {
    allProductOffers,
    paymentIntent,
    setPaymentIntent,
    formattedTime,
    allPages,
    setAllPages,
    orderPlaced,
    selectedState,
  } = mainStore();
  const { managePurchase } = PurchaseStore();
  const [loading, setLoading] = useState(false);
  const [loadingGLobal, setLoadingGlobal] = useState(false);

  const [pillowDeals, setPillowDeals] = useState([]);

  const [selectedDeal, setSelectedDeal] = useState({});

  useEffect(() => {
    initializeUpsell1B();
  }, []);

  useEffect(() => {
    if (paymentIntent) {
      const pillowcases = allProductOffers.products.filter((cases) => {
        return ["NZ2004", "NZ2005", "NZ2006"].includes(cases.sku);
      });
      console.log("pillowcases ~ pillowcases:", pillowcases);

      if (pillowcases.length) {
        setSelectedDeal(pillowcases[0]);
        setPillowDeals(pillowcases);
      }
    }
  }, [allProductOffers, paymentIntent]);

  const initializeUpsell1B = async () => {
    if (!paymentIntent) {
      navigate("/");
      return;
    }

    if (orderPlaced) {
      navigate("/thank-you");
      return;
    }

    if (allPages.includes("up-sell-1b")) {
      setLoadingGlobal(true);
      const newPaymentIntent = { ...paymentIntent };
      newPaymentIntent.step = "step-upsell";
      newPaymentIntent.finalizePurchase = true;
      const res = await managePurchase(newPaymentIntent);
      if (res?.success) {
        trackUpsellAccepted(selectedDeal.sku);
        setPaymentIntent(res.data);
        navigate("/thank-you");
      }
      setLoadingGlobal(false);
    }

    const pages = [...allPages];
    pages.push("up-sell-1b");
    setAllPages(pages);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    trackPageViewEvent({ page_type: "Upsell 1B" });
  };

  const onAddPillowCase = async () => {
    const newPaymentIntent = { ...paymentIntent };
    newPaymentIntent.step = "step-upsell";
    newPaymentIntent.details.items.push({
      title: selectedDeal.title,
      price: selectedDeal.afterDiscountValue,
      quantity: 1,
      sku: selectedDeal.sku,
      product_id: selectedDeal.productId,
      // tax_lines: calculateTax(selectedState, selectedDeal.afterDiscountValue),
    });

    setLoading(true);
    const res = await managePurchase(newPaymentIntent);
    console.log("onAddPillowCase ~ res:", res);

    if (res?.success) {
      trackUpsellAccepted(selectedDeal.sku);
      setPaymentIntent(res.data);
      navigate("/up-sell-3b");
      setLoading(false);
    } else {
      alert(res?.errorMessage);
      setLoading(false);
    }
  };

  const navigateToNextUpsell = () => {
    setLoading(false);
    if (paymentIntent.details.upgradedToKing) {
      navigate("/up-sell-2b");
    } else {
      navigate("/up-sell-2a");
    }
  };

  return (
    <>
      {loadingGLobal && (
        <div className="global-loader">
          <ClipLoader color="#fff" size={100} />
        </div>
      )}
      <div>
        <div className="progressbar-section">
          <div>
            <img
              src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f222c4dc2ef4647b6c690_progress%20bar.svg"
              loading="lazy"
              alt=""
              className="image-18"
            />
          </div>
        </div>
        <div className="logo-section">
          <div className="container-3 container-upsell w-container">
            <div className="upsell-header">
              <div className="logo-block upsell-logo-block">
                <img
                  src="../images/logo_v02.png"
                  loading="lazy"
                  width="113"
                  alt=""
                />
              </div>
              <div className="progress-container">
                <div className="progress-step">
                  <a href="#" className="progress-link w-inline-block">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2d756903139a8964573_one.png"
                      loading="lazy"
                      alt=""
                      className="image-21 link-img"
                    />
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f27534ea18b1c3eedbe8a_st1.svg"
                      loading="lazy"
                      alt=""
                      className="pr-r r-pr-r"
                    />
                  </a>
                  <p className="progress-p">Checkout</p>
                </div>
                <div className="progress-step">
                  <a href="#" className="progress-link w-inline-block">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2ebbce1fa36907e580e_two.png"
                      loading="lazy"
                      alt=""
                      className="image-21 link-img"
                    />
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f277596949e5bacc01176_st2.svg"
                      loading="lazy"
                      width="414"
                      alt=""
                      className="pr-r c-pr-r"
                    />
                  </a>
                  <p className="progress-p">Special Deal</p>
                </div>
                <div className="progress-step ls">
                  <div className="progress-link">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2f9037c341fc9b203c4_three.png"
                      loading="lazy"
                      alt=""
                      className="image-21 link-img"
                    />
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f279000ea000b17acdf08_st3.svg"
                      loading="lazy"
                      alt=""
                      className="pr-r l-pr-r"
                    />
                  </div>
                  <p className="progress-p">Summary</p>
                </div>
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa9a0a2c9d72c22d4eb3c_rl.svg"
                  loading="lazy"
                  alt=""
                  className="image-22"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          data-variant-id="42536684224688"
          className="main-section main-section-upsell"
        >
          <div className="container-3 container-upsell main-container-upsell w-container">
            <div className="upsell-main-top-block-old-upsell">
              <h5 className="sale-title-old-upsell">
                WAIT! YOUR ORDER ISN’T COMPLETE WITHOUT OUR{" "}
                <span className="text-span-86">COOLING PILLOWCASES!</span>
              </h5>
            </div>
            <div className="timer-div-old ">
              <div className="note-text-old">
                Limited time offer expires in{" "}
                <span id="timer" className="ff-ti">
                  {formattedTime}
                </span>{" "}
                minutes, so don&#x27;t miss out.
              </div>
            </div>
            <div className="center-div">
              <p className="sale-p-old">
                ⚠ WARNING: Your old pillowcases might not fit the Nuzzle Pillow.
                <br />
                <br />
                That’s why we’re offering you an exclusive deal on our cooling
                pillowcases, custom-made to fit your Nuzzle pillow perfectly.
              </p>
            </div>
            <div className="columns upsell-cl-old w-row">
              <div className="column-2 upsell-slider-coloumn w-col w-col-6">
                <div className="upsell-slider-wrapper">
                  <div className="main-dots upsell-dots">
                    <div className="slide-dot">
                      <img
                        src="../images/bb1.png"
                        loading="lazy"
                        srcset="../images/bb1-p-500.png 500w, ../images/bb1.png 754w"
                        sizes="(max-width: 479px) 96vw, (max-width: 767px) 100vw, (max-width: 991px) 45vw, 445px"
                        alt=""
                        className="slide-dot-iimg"
                      />
                    </div>
                    <div className="slide-dot"></div>
                    <div className="slide-dot"></div>
                    <div className="slide-dot"></div>
                    <div className="slide-dot"></div>
                  </div>
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f176839d9035414ca219f_prev.svg"
                    loading="lazy"
                    alt=""
                    className="prev-arr slick-arrow upsell-arr"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f17805bff702023229745_next.svg"
                    loading="lazy"
                    alt=""
                    className="next-arr slick-arrow upsell-arr"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/6270e07371b4bf03848279bc_best.png"
                    loading="lazy"
                    alt=""
                    className="best-slider-icon"
                  />
                </div>
              </div>
              <div className="column-5 w-col w-col-6">
                <div
                  id="redeem"
                  className="main-text-block upsell-main-text-block"
                >
                  <h1 className="main-title-old">Cooling Pillowcase</h1>
                  <div className="stars-block">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/623750a74497753d5f3105f3_rating%20stars.svg"
                      loading="lazy"
                      alt=""
                    />
                    <p className="stars-text upsell-stars-text">410 Reviews</p>
                  </div>
                  <div className="gift-row upsell-gift-row">
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e96079fa1145743416b70_free.svg"
                      loading="lazy"
                      alt=""
                      className="image-13"
                    />
                    <div className="gift-text-block">
                      <p className="paragraph-15 upsell-p15">
                        <strong className="bold-text-4">
                          This offer is available only with today’s purchase!
                        </strong>
                      </p>
                    </div>
                  </div>
                  {/* <p className="paragraph-11 upsell-p11">
                    Regular pillowcases can fit the Nuzzle pillow, but it
                    won&#x27;t fit perfectly. That&#x27;s why we recommend our
                    tailor-made Nuzzle pillowcases for maximum comfort.
                  </p>
                  <div className="main-list-block fronone">
                    <div className="main-list-row">
                      <img
                        src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                        loading="lazy"
                        alt=""
                      />
                      <p className="list-p">
                        Supercharge the cooling effect of your Nuzzle pillow{" "}
                      </p>
                    </div>
                    <div className="main-list-row">
                      <img
                        src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                        loading="lazy"
                        alt=""
                      />
                      <p className="list-p">
                        Advanced IceTech® weave wicks moisture{" "}
                      </p>
                    </div>
                    <div className="main-list-row">
                      <img
                        src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                        loading="lazy"
                        alt=""
                      />
                      <p className="list-p">
                        Tested to keep your pillow 2-3 degrees cooler{" "}
                      </p>
                    </div>
                    <div className="main-list-row">
                      <img
                        src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e9dd6b16093516ed79df4_list%20icon.svg"
                        loading="lazy"
                        alt=""
                      />
                      <p className="list-p">
                        Protect your pillow from germs, bacteria, and other
                        allergens.
                      </p>
                    </div>
                  </div> */}
                  <div
                    data-current="Price Tier 3"
                    data-easing="ease"
                    data-duration-in="0"
                    data-duration-out="0"
                    className="tabs-3 w-tabs"
                  >
                    <div className="tabs-menu-3 w-tab-menu">
                      {pillowDeals.map((pillow, index) => (
                        <a
                          className={`upsell-select-variant _2_bottles w-inline-block w-tab-link ${
                            selectedDeal.sku === pillow.sku ? "w--current" : ""
                          }`}
                          key={index}
                          onClick={() => setSelectedDeal(pillow)}
                        >
                          <img
                            loading="lazy"
                            src={pillow.img}
                            alt=""
                            className="image-85"
                          />
                          <div className="price-type-6">
                            <span className="text-span-76">
                              {pillow.quantity}x{" "}
                            </span>
                            <br />
                            <span className="text-span-78">
                              {pillow.label}
                              <br />
                            </span>
                            <span className="text-span-95 unit_price">
                              ‍<br />
                            </span>
                            <span className="comp_price-2">
                              Orig ${pillow.retailValue}
                            </span>
                            <div>
                              $
                              {Math.round(
                                (pillow.afterDiscountValue / pillow.quantity) *
                                  100
                              ) / 100}
                              /ea
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                    <div className="tabs-content-4 w-tab-content">
                      <div
                        data-w-tab="Price Tier 3"
                        className="w-tab-pane w--tab-active"
                      >
                        <div className="tab-content-inner">
                          <div
                            data-current="Price Tier 1"
                            data-easing="ease"
                            data-duration-in="0"
                            data-duration-out="0"
                            className="tabs-3 w-tabs"
                          >
                            <div className="tabs-content-4 w-tab-content">
                              <div
                                data-w-tab="Price Tier 1"
                                className="tab-content-2 w-tab-pane w--tab-active"
                              >
                                <div className="tab-content-inner">
                                  <div
                                    data-upsell-type="add-variant"
                                    className="accept_upsell_button checkout-accept-upsell w-inline-block"
                                  >
                                    <button
                                      className={`button-gold ${
                                        loading ? "order-processing" : ""
                                      }`}
                                      style={{
                                        backgroundColor: loading
                                          ? "#294a59"
                                          : "#29af5c",
                                      }}
                                      disabled={loading}
                                      onClick={() => onAddPillowCase()}
                                    >
                                      <div className="button-txt-icon-2">
                                        <div className="add-to-order">
                                          {loading ? (
                                            <span className="pay-now">
                                              <i> Processing Order</i>{" "}
                                              <PulseLoader
                                                color="#ffffff"
                                                size={8}
                                              />
                                            </span>
                                          ) : (
                                            <span>YES! ADD TO MY ORDER!</span>
                                          )}
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="paypal-button-container"></div>
                  <div className="form-information upsell-form-inf">
                    <div className="g-text">
                      TRY IT RISK FREE! 90-DAY MONEY BACK GUARANTEE
                    </div>
                  </div>
                  <a
                    className="no-offer-block w-inline-block cursor-pointer"
                    onClick={() => {
                      navigateToNextUpsell();
                    }}
                  >
                    <p className="no-offer-text">
                      No, thank you! I don&#x27;t want this one time offer.{" "}
                    </p>
                    <img
                      src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625ef1f759479c8b18be44f8_Path.svg"
                      loading="lazy"
                      alt=""
                      className="image-17"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-6">
          <div className="inner-upsell">
            <div className="text-block-24">
              <a
                href="../legal/privacy-policy.html"
                target="_blank"
                className="link-5"
              >
                Privacy Policy
              </a>{" "}
              | 
              <a
                href="../legal/terms-conditions.html"
                target="_blank"
                className="link-5"
              >
                Terms &amp; Conditions
              </a>{" "}
              |{" "}
              <a
                href="../legal/return-policy.html"
                target="_blank"
                className="link-8"
              >
                Refund Policy
              </a>{" "}
              |{" "}
              <a
                href="../legal/contact-us.html"
                target="_blank"
                className="link-9"
              >
                Contact
              </a>{" "}
              | 
              <a href="../about-us.html" target="_blank" className="link-10">
                About
              </a>
              <br />© 2023 Nuzzle. All Rights Reserved.
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpSell1B;
