import React, { useEffect } from "react";
import Header from "./header";
import Banner from "./banner";
import SliderMain from "./slider";
import Info from "./info";
import RatingReviews from "./rating-reviews";
import FaqMoney from "./faq-money";
import Extras from "./extras";
import Footer from "./footer";
import { trackPageViewEvent } from "mixpanel/events";
import mainStore from "store/store";
import { useNavigate } from "react-router-dom";
import GlobalTimerStore from "store/globalTimer";
import moment from "moment";

function Home() {
  const navigate = useNavigate();

  const {
    allPages,
    setAllPages,
    paymentIntent,
    setPaymentIntent,
    orderPlaced,
    setOrderPlaced,
    setTaboolaClickId,
    setVoluumCid,
    setUtmParams,
  } = mainStore();

  const { hours, minutes, seconds } = GlobalTimerStore();

  const addBusinessDays = (startDate, daysToAdd) => {
    let currentDate = moment(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.add(1, "days");
      if (currentDate.isoWeekday() <= 5) {
        addedDays++;
      }
    }
    return currentDate;
  };
  const deliverDate = addBusinessDays(moment(), 1).format("ddd, MMM Do");

  useEffect(() => {
    resetStoreData();
    setTablooladId();
    trackPageViewEvent({ page_type: "Home" });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const resetStoreData = () => {
    setAllPages([]);
    setPaymentIntent(null);
    setOrderPlaced(false);
  };

  const setTablooladId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const tclid = queryParams.get("click_id");

    const params = new URLSearchParams(window.location.search);
    const utm_source = params.get("utm_source");
    const utm_campaign = params.get("utm_campaign");
    const utm_medium = params.get("utm_medium");

    setUtmParams({
      utm_source: utm_source,
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
    });

    if (tclid) {
      setTaboolaClickId(tclid);
    }

    if (
      window.dtpCallback &&
      typeof window.dtpCallback.getClickID === "function"
    ) {
      const clickID = window.dtpCallback.getClickID();
      console.log("dpt Click ID:", clickID);
      setVoluumCid(clickID);
    } else {
      console.warn(
        "window.dtpCallback.getClickID is not defined or not a function"
      );
    }
  };

  const onOrder = () => {
    navigate("/checkout");
  };

  return (
    <>
      <div class="wrapper mobile-lp3">
        <Header />
        <SliderMain />
        <Info />
        <RatingReviews />
        <Extras />
        <Footer />
      </div>
      <div class="selection_cta-box display-none">
        <div class="ck_new-badge-block">
          <div class="co_left-block">
            <img
              loading="lazy"
              src="images/small-fire.svg"
              alt=""
              class="fire-badge-icon"
            />
            <div class="pp-left-badge">
              <strong class="pp-left-badge-bold">Hurry! 126 people</strong> are
              on checkout
            </div>
          </div>
          <div class="co_right-block">
            <img
              loading="lazy"
              src="images/box-fast.svg"
              alt=""
              class="fast-box-icon"
            />
            <div class="pp-right-badge">Ships by  </div>
            <div class="pp-right-badge-date">{deliverDate}</div>
          </div>
        </div>
        <button
          data-target="drawer"
          class="proceed-ck-btn w-inline-block"
          onClick={onOrder}
        >
          <div class="ck-countdown-new-text">
            Sale Ends In{" "}
            <span id="mobile_timer">
              {hours}:{minutes}:{seconds}
            </span>
          </div>
          <div class="ck_new-btn-text">ORDER NOW AND SAVE</div>
        </button>
      </div>
    </>
  );
}

export default Home;
