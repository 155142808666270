import { useEffect, useMemo } from "react";
import mainStore from "store/store";
import Paypal from "./paypal";
import NMI from "./nmi";
import ProductDetailsSubCheckout from "./product-details-subcheckout";
import DetailsHeaader from "./detailsHeader";
import Header from "../header";
import Trust from "../trust";

const SubCheckout = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const { summaryBundle, upgradeToKing } = mainStore();

  const returnTotal = useMemo(() => {
    return summaryBundle.afterDiscountValue + summaryBundle.shippingCost;
  }, [summaryBundle, upgradeToKing]);

  return (
    <>
      <Header />
      <div class="checkout-container body-2">
        <DetailsHeaader />
        <div class="rtc-checkout-3 gr1 ds1 dw1">
          <div class="main-checkout-box newbox">
            <ProductDetailsSubCheckout />
            <div class="new_checkout_div newstyle">
              <div data-i18n="express_checkout" class="secure-checkout">
                Express Checkout
              </div>
              <div class="cc-trust v02cc">
                <div class="paypal_selection-box">
                  <Paypal
                    offer={summaryBundle}
                    amount={returnTotal}
                    upgradedToKing={upgradeToKing}
                  />
                </div>
                <div class="divider-wrap reviews">
                  <div class="grey-line"></div>
                  <div class="sign-up-text">OR</div>
                  <div class="grey-line"></div>
                </div>
                <div class="credit-card_selection">
                  <div class="cc-icon_box">
                    <img
                      src="../images/amex1.svg"
                      loading="lazy"
                      alt=""
                      class="cc-icon-image"
                    />
                  </div>
                  <div class="cc-icon_box">
                    <img
                      src="../images/visa1.svg"
                      loading="lazy"
                      alt=""
                      class="cc-icon-image"
                    />
                  </div>
                  <div class="cc-icon_box">
                    <img
                      src="../images/so1.svg"
                      loading="lazy"
                      alt=""
                      class="cc-icon-image"
                    />
                  </div>
                  <div class="cc-icon_box">
                    <img
                      src="../images/discover1.svg"
                      loading="lazy"
                      alt=""
                      class="cc-icon-image"
                    />
                  </div>
                  <div class="cc-icon_box">
                    <img
                      src="../images/master1.svg"
                      loading="lazy"
                      alt=""
                      class="cc-icon-image"
                    />
                  </div>
                </div>
              </div>
              {/* <div class="paypal-wrap">
                <p class="paragraph-32">Express Checkout</p>
                <Paypal
                  offer={summaryBundle}
                  amount={returnTotal}
                  upgradedToKing={upgradeToKing}
                />
              </div> */}
              {/* <div class="paypal-wrap or-wrap">
                <p class="paragraph-32 or-txt">OR</p>
              </div> */}
              <div id="paypal_divider" class="div-block-682"></div>
              <NMI
                offer={summaryBundle}
                amount={summaryBundle.afterDiscountValue}
                upgradedToKing={upgradeToKing}
              />
            </div>
          </div>
        </div>
      </div>
      <Trust />
    </>
  );
};

export default SubCheckout;
