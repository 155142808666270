const taxRates = [
  { state: "Alabama", code: "AL", rate: 4 },
  { state: "Alaska", code: "AK", rate: 0 },
  { state: "Arizona", code: "AZ", rate: 5.6 },
  { state: "Arkansas", code: "AR", rate: 6.5 },
  { state: "California", code: "CA", rate: 7.25 },
  { state: "Colorado", code: "CO", rate: 2.9 },
  { state: "Connecticut", code: "CT", rate: 6.35 },
  { state: "Delaware", code: "DE", rate: 0 },
  { state: "Florida", code: "FL", rate: 6 },
  { state: "Georgia", code: "GA", rate: 4 },
  { state: "Hawaii", code: "HI", rate: 4 },
  { state: "Idaho", code: "ID", rate: 6 },
  { state: "Illinois", code: "IL", rate: 6.25 },
  { state: "Indiana", code: "IN", rate: 7 },
  { state: "Iowa", code: "IA", rate: 6 },
  { state: "Kansas", code: "KS", rate: 6.5 },
  { state: "Kentucky", code: "KY", rate: 6 },
  { state: "Louisiana", code: "LA", rate: 4.45 },
  { state: "Maine", code: "ME", rate: 5.5 },
  { state: "Maryland", code: "MD", rate: 6 },
  { state: "Massachusetts", code: "MA", rate: 6.25 },
  { state: "Michigan", code: "MI", rate: 6 },
  { state: "Minnesota", code: "MN", rate: 6.875 },
  { state: "Mississippi", code: "MS", rate: 7 },
  { state: "Missouri", code: "MO", rate: 4.225 },
  { state: "Montana", code: "MT", rate: 0 },
  { state: "Nebraska", code: "NE", rate: 5.5 },
  { state: "Nevada", code: "NV", rate: 6.85 },
  { state: "New Hampshire", code: "NH", rate: 0 },
  { state: "New Jersey", code: "NJ", rate: 6.625 },
  { state: "New Mexico", code: "NM", rate: 5.125 },
  { state: "New York", code: "NY", rate: 4 },
  { state: "North Carolina", code: "NC", rate: 4.75 },
  { state: "North Dakota", code: "ND", rate: 5 },
  { state: "Ohio", code: "OH", rate: 5.75 },
  { state: "Oklahoma", code: "OK", rate: 4.5 },
  { state: "Oregon", code: "OR", rate: 0 },
  { state: "Pennsylvania", code: "PA", rate: 6 },
  { state: "Rhode Island", code: "RI", rate: 7 },
  { state: "South Carolina", code: "SC", rate: 6 },
  { state: "South Dakota", code: "SD", rate: 4.5 },
  { state: "Tennessee", code: "TN", rate: 7 },
  { state: "Texas", code: "TX", rate: 6.25 },
  { state: "Utah", code: "UT", rate: 4.85 },
  { state: "Vermont", code: "VT", rate: 6 },
  { state: "Virginia", code: "VA", rate: 5.3 },
  { state: "Washington", code: "WA", rate: 6.5 },
  { state: "West Virginia", code: "WV", rate: 6 },
  { state: "Wisconsin", code: "WI", rate: 5 },
  { state: "Wyoming", code: "WY", rate: 4 },
  { state: "Test", code: "ON", rate: 3 },
];

export const getDeviceName = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/iphone/.test(userAgent)) return "iPhone";
  if (/ipad/.test(userAgent)) return "iPad";
  if (/macintosh/.test(userAgent) && "ontouchend" in document) return "iPad"; // For macOS iPads
  if (/android/.test(userAgent)) return "Android Device";
  if (/windows phone/.test(userAgent)) return "Windows Phone";
  if (/linux/.test(userAgent) && /mobile/.test(userAgent))
    return "Linux Mobile";
  if (/macintosh|mac os x/.test(userAgent)) return "Mac";
  if (/windows/.test(userAgent)) return "Windows PC";

  return "Unknown Device";
};

export const calculateTax = (selectedState, amount) => {
  const foundState = taxRates.find((state) => {
    return state.code === selectedState;
  });
  console.log(" foundState ~ foundState:", foundState);

  if (foundState) {
    const taxLines = [];
    taxLines.push({
      price: (amount * foundState.rate) / 100, // Tax amount
      rate: foundState.rate / 100, // Tax rate (e.g., 0.1 for 10%)
      title: "Sales Tax",
    });

    return taxLines;
  }
};
