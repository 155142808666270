import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import mainStore from "store/store";
import PurchaseStore from "store/purchase";
import {
  trackUpsellAccepted,
  trackPageViewEvent,
  trackPurchaseEvent,
} from "mixpanel/events";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import { calculateTax } from "utils/global";

function UpSell2A() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    allProductOffers,
    paymentIntent,
    setPaymentIntent,
    formattedTime,
    allPages,
    setAllPages,
    orderPlaced,
    selectedState,
  } = mainStore();
  const { managePurchase } = PurchaseStore();

  const [loading, setLoading] = useState(false);
  const [loadingGLobal, setLoadingGlobal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState({});

  useEffect(() => {
    initializeUpsell2A();
  }, []);

  useEffect(() => {
    if (window.hj) {
      window.hj("stateChange", location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (paymentIntent) {
      const pillowcases = allProductOffers.products.find((cases) => {
        return paymentIntent.details?.offer.quantity == 4
          ? cases.sku === "NZ9002"
          : cases.sku === "NZ9001";
      });
      console.log("pillowcases ~ pillowcases:", pillowcases);

      if (pillowcases) {
        setSelectedDeal(pillowcases);
      }
    }
  }, [allProductOffers, paymentIntent]);

  const quantity = useMemo(() => {
    if (paymentIntent?.details?.offer.quantity > 1) {
      return paymentIntent?.details?.offer.quantity;
    }
    return 2;
  }, [paymentIntent]);

  const initializeUpsell2A = async () => {
    if (!paymentIntent) {
      navigate("/");
      return;
    }

    if (orderPlaced) {
      navigate("/thank-you");
      return;
    }

    if (allPages.includes("up-sell-2a")) {
      setLoadingGlobal(true);
      const newPaymentIntent = { ...paymentIntent };
      // newPaymentIntent.finalizePurchase = true;
      newPaymentIntent.eventDetails.url = window.location?.href;
      const res = await managePurchase(newPaymentIntent);
      if (res?.success) {
        trackUpsellAccepted(selectedDeal.sku);
        // trackPurchaseEvent(newPaymentIntent);
        setPaymentIntent(res.data);
        navigate("/up-sell-3a");
      }
      setLoadingGlobal(false);
    }

    const pages = [...allPages];
    pages.push("up-sell-2a");
    setAllPages(pages);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    trackPageViewEvent({ page_type: "Upsell 2A" });
  };

  const onAddPillow = async () => {
    const newPaymentIntent = { ...paymentIntent };
    newPaymentIntent.step = "step-upsell";
    // newPaymentIntent.finalizePurchase = true;
    newPaymentIntent.eventDetails.url = window.location?.href;
    newPaymentIntent.details.items.push({
      title: selectedDeal.title,
      price: selectedDeal.afterDiscountValue,
      quantity: 1,
      sku: selectedDeal.sku,
      product_id: selectedDeal.productId,
      // tax_lines: calculateTax(selectedState, selectedDeal.afterDiscountValue),
    });

    setLoading(true);
    const res = await managePurchase(newPaymentIntent);

    if (res?.success) {
      trackUpsellAccepted(selectedDeal.sku);
      // trackPurchaseEvent(newPaymentIntent);
      setPaymentIntent(res.data);
      navigate("/up-sell-3a");
      setLoading(false);
    } else {
      alert(res?.errorMessage);
      setLoading(false);
    }
  };

  const navigateToNextUpsell = () => {
    if (loading) {
      return;
    }
    setLoadingGlobal(true);
    setTimeout(() => {
      setLoading(false);
      setLoadingGlobal(false);
      navigate("/up-sell-3a");
    }, 1000);
  };

  return (
    <>
      {loadingGLobal && (
        <div className="global-loader">
          <ClipLoader color="#fff" size={100} />
        </div>
      )}
      <div class="main-section main-section-upsell">
        <div class="small_container for-upsell w-container">
          <div class="warning_banner">
            <h5 class="sale-title">
              LAST
              <br />
              CHANCE
            </h5>
            <div id="warning" class="w-embed"></div>
          </div>
          <div class="secondary-heading_box">
            <div class="secondary-heading_text is-big">
              YOU WILL NOT <span class="text-span-187">SEE</span> THIS OFFER
              AGAIN
            </div>
          </div>
          <div class="limited-heading_box">
            <div class="sale-p">EXTRA SAVINGS!</div>
          </div>
          <div class="upsell_content">
            <div class="up_con-one">
              <div class="up_con-content-first">
                <div class="up_con-text">
                  One Time Only - Our Best Ever Offer On {quantity}{" "}
                  Ultra-Cooling, Custom-Fitted Pillowcases
                </div>
                <div class="offer-price_box">
                  <div class="offer_price-text">
                    ONLY{" "}
                    <span class="text-span-183">
                      $
                      {(selectedDeal?.afterDiscountValue / quantity)?.toFixed(
                        2
                      )}
                    </span>
                    <span class="text-span-185">/ea</span>
                  </div>
                </div>
              </div>
              <div class="upsell-slider-wrapper">
                <div class="inter_con-box">
                  <img
                    sizes="(max-width: 479px) 100vw, 384px"
                    srcset="
                    ../images/Upsell-Funnel--08-p-500.jpg   500w,
                    ../images/Upsell-Funnel--08-p-800.jpg   800w,
                    ../images/Upsell-Funnel--08-p-1080.jpg 1080w,
                    ../images/Upsell-Funnel--08-p-1600.jpg 1600w,
                    ../images/Upsell-Funnel--08-p-2000.jpg 2000w,
                    ../images/Upsell-Funnel--08.jpg        2083w
                  "
                    alt=""
                    src="../images/Upsell-Funnel--08.jpg"
                    loading="lazy"
                    class="inter_con-image"
                  />
                </div>
              </div>
            </div>
            <div class="up_con-two">
              <div class="trust_badge-box">
                <div class="thumb_icon-box">
                  <img
                    src="../images/thumb-blue.png"
                    loading="lazy"
                    alt=""
                    class="thumnb_icon"
                  />
                </div>
                <div>
                  <div class="text-block-239">
                    87% of people claimed this offer
                  </div>
                </div>
              </div>
              <div class="upsell_btn-box">
                <button
                  class="upsell_next-btn w-button"
                  style={{
                    backgroundColor: loading ? "#294a59" : "#29af5c",
                    boxShadow: loading
                      ? "0 3px 0 1px #294a59"
                      : "0 3px 0 1px #29af5c",
                  }}
                  disabled={loading}
                  onClick={() => onAddPillow()}
                >
                  {loading ? (
                    <span className="pay-now">
                      <i> ADDING TO ORDER</i>{" "}
                      <PulseLoader color="#ffffff" size={8} />
                    </span>
                  ) : (
                    <span>
                      Add to Order <span class="text-span-186"></span>
                    </span>
                  )}
                </button>
              </div>
              <div class="discount_timer-box">
                <div class="discount_timer-text">
                  Your discount expires in{" "}
                  <span class="discount_timer-number">
                    {formattedTime} mins
                  </span>
                </div>
              </div>
              <div class="next_step-box">
                <a
                  class="next_step-text cursor-pointer"
                  onClick={() => {
                    navigateToNextUpsell();
                  }}
                >
                  No Thanks - I Don't Want This Discount
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpSell2A;
