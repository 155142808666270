import React from "react";
import { useNavigate } from "react-router-dom";
import GlobalTimerStore from "store/globalTimer";

function Footer() {
  const navigate = useNavigate();
  const { hours, minutes, seconds } = GlobalTimerStore();

  const onOrder = () => {
    navigate("/checkout");
  };

  const onRoute = (route) => {
    navigate(route);
  };

  return (
    <div>
      <section id="gua-wrapper" class="last-sec">
        <div class="gg-block-bottom">
          <div class="gua-div">
            <div class="div-block-506">
              <img
                src="images/gg1.png"
                loading="lazy"
                width="170"
                alt="nuzzle, canada, pillow, sleep"
                class="gua-img"
              />
            </div>
            <div class="dua-text-block">
              <h1 class="con-heading-left">
                Try It At Our Risk For <br />
                90 Days Or Your Money Back
              </h1>
              <div class="three-con-para guatext">
                Try it for 90 days entirely at our risk. If you are not
                absolutely delighted with the Nuzzle pillow from the very first
                night, simply return it for a full refund. Contact our full-time
                dedicated support team for assistance, no questions asked.
              </div>
            </div>
          </div>
        </div>
        <div class="btn-con-bottom w-container">
          {/* <a
            class="new-btn cta_btn w-inline-block cursor-pointer"
            onClick={onOrder}
          >
            <div>
              <div class="text-block-183">YES! REDEEM 50% OFF</div>
            </div>
            <div>
              <img
                src="images/a34.svg"
                loading="lazy"
                width="153.5"
                alt=""
                class="image-109"
              />
            </div>
          </a> */}
          <button
            data-target="drawer"
            class="proceed-ck-btn w-inline-block w381px"
            onClick={onOrder}
          >
            <div class="ck-countdown-new-text">
              Sale Ends In{" "}
              <span id="mobile_timer">
                {hours}:{minutes}:{seconds}
              </span>
            </div>
            <div class="ck_new-btn-text">ORDER NOW AND SAVE</div>
          </button>
          <div class="bb-div">
            <div>
              <img
                src="images/lock_black.svg"
                loading="lazy"
                alt=""
                class="lock-img"
              />
            </div>
            <div>
              <div class="below-btn-text blk">Safe &amp; Secure</div>
            </div>
            <div>
              <img
                src="images/cc-light_1.svg"
                loading="lazy"
                width="168"
                alt=""
                class="cc-cards-img"
              />
            </div>
          </div>
        </div>
        <div class="w-embed">
          <div id="benefits_now"></div>
        </div>
      </section>
      <section class="footer-new-sec">
        <img
          src="images/footer-logo.png"
          loading="lazy"
          alt=""
          class="footer-logo-img formobile"
        />
        <div class="div-block-553">
          <div class="div-block-509">
            <div class="footer-link-text">
              <a
                target="_blank"
                class="link-20"
                onClick={() => onRoute("/about-us")}
              >
                About Us |
              </a>

              <a
                target="_blank"
                class="link-19"
                onClick={() => onRoute("/terms-conditions")}
              >
                Terms &amp; Conditions |
              </a>

              <a
                target="_blank"
                class="link-18"
                onClick={() => onRoute("/privacy-policy")}
              >
                Privacy Policy |
              </a>

              <a
                target="_blank"
                class="link-14"
                onClick={() => onRoute("/return-policy")}
              >
                Returns |
              </a>

              <a
                target="_blank"
                class="link-15"
                onClick={() => onRoute("/contact-us")}
              >
                Contact Us
              </a>
              <a href="#" target="_blank" class="link-one"></a>
            </div>
          </div>
          <img
            src="images/footer-logo.png"
            loading="lazy"
            alt=""
            class="footer-logo-img"
          />
          <div class="copy-right-text">
            Copyright 2024. All rights reserved.
          </div>
          <div class="copy-right-text text-center">
            Dynamic Home Group Inc. 7886 Deering Ave, Canoga Park, CA 91304
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
