import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import mainStore from "store/store";
import PurchaseStore from "store/purchase";
import { getDeviceName } from "utils/global";
import { calculateTax } from "utils/global";

function Paypal({ offer, amount, upgradedToKing }) {
  const navigate = useNavigate();
  const [paypalShowAmount] = useState(amount + 3.99);

  const {
    setPaymentIntent,
    startCountdown,
    startTimer,
    connectionId,
    taboolaClickId,
    voluumCid,
    uniqueUserId,
    utmParams,
    selectedState,
    setSelectedState,
  } = mainStore();
  const { createPaypalOrder, getPaypalOrderDetails, managePurchase } =
    PurchaseStore();

  const createOrder = async () => {
    const res = await createPaypalOrder({ amount: amount });
    if (res?.id) {
      return res.id;
    }
  };

  const onApprove = async (info) => {
    const data = await getPaypalOrderDetails(info.orderID);
    const shipping = data.purchase_units[0]?.shipping.address;
    setSelectedState(shipping.admin_area_1);

    const customer = {
      firstName: data.payer?.name?.given_name,
      lastName: data.payer?.name?.surname,
      email: data.payer.email_address,
      line1: shipping.address_line_1,
      line2: "",
      city: shipping.admin_area_2,
      state: shipping.admin_area_1,
      country: shipping.country_code,
      postal_code: shipping.postal_code,
    };
    customer.billingAddress = { ...customer };

    const payload = {
      socketId: connectionId,
      paymentId: data.id,
      gateway: "paypal",
      step: "step-1",
      userEmail: customer.email,
      isOrderCreated: false,
      taboolaClickId: taboolaClickId,
      voluumCid: voluumCid,
      uniqueUserId: uniqueUserId,
      paymentRequired: true,
      eventDetails: {
        url: window.location?.href,
        device: getDeviceName(),
        utm_campaign: utmParams.utm_campaign,
        utm_medium: utmParams.utm_medium,
        utm_source: utmParams.utm_source,
      },
      details: {
        customer: customer,
        upgradedToKing: upgradedToKing,
        currency: "usd",
        offer: offer,
        items: [
          {
            title: offer.title,
            price: amount,
            quantity: 1,
            sku: offer.sku,
            product_id: offer.productId,
            // tax_lines: calculateTax(shipping.admin_area_1, amount),
          },
          {
            title: "Shipping Insurance",
            price: 3.99,
            quantity: 1,
            sku: "NZ1201",
            product_id: "7782857343152",
          },
        ],
      },
    };
    console.log(" onApprove ~ payload:", payload);

    const res = await managePurchase(payload);

    if (res?.success) {
      setPaymentIntent(res.data);
      startCountdown();
      startTimer();
      if (upgradedToKing) {
        navigate("/up-sell-1b");
      } else {
        navigate("/up-sell-1a");
      }
    } else {
      alert(res?.errorMessage);
    }
  };

  return (
    <>
      <PayPalScriptProvider
        key={amount}
        options={{
          "client-id":
            "AaXsYfCq4nyz5gnUFCFwuDzcUeolyGOhRA8LgPJkcGto2Q6LfbqbdLSeAc-Qduex06tTlUX373xWdnCx",
        }}
      >
        <PayPalButtons
          fundingSource="paypal"
          createOrder={() => {
            return createOrder();
          }}
          onApprove={(data) => {
            return onApprove(data);
          }}
          onError={() => {}}
        />
      </PayPalScriptProvider>
    </>
  );
}

export default Paypal;
