import React, { useEffect, useMemo, useState } from "react";
import mainStore from "store/store";
import { useNavigate } from "react-router-dom";
import { trackPageViewEvent } from "mixpanel/events";

function ThankYou() {
  const navigate = useNavigate();
  const { paymentIntent, setOrderPlaced } = mainStore();
  const [orderDetails, setOrderDetails] = useState({
    details: { offer: {}, items: [], customer: {} },
    gateway: "",
    amount: 0,
  });

  useEffect(() => {
    if (!paymentIntent) {
      navigate("/");
    }
    trackPageViewEvent({ page_type: "Thank You" });

    setOrderPlaced(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  useEffect(() => {
    if (paymentIntent) {
      setOrderDetails(paymentIntent);
    }
  }, [paymentIntent]);

  const returnSaveTotalSummay = useMemo(() => {
    const bundle = orderDetails.details.offer;
    const upgradeToKing = orderDetails.details.upgradedToKing;
    if (!bundle) {
      return 0;
    }
    let amount = 0;
    // if (upgradeToKing) {
    //   amount = bundle.retailValue - (bundle.afterDiscountValue + upgradeAmount);
    // }
    amount = bundle.retailValue - bundle.afterDiscountValue;

    return amount.toFixed(2);
  }, [orderDetails]);

  const returnTotal = useMemo(() => {
    const items = orderDetails.details.items;

    let amount = items.reduce((acc, item) => {
      return acc + item.price;
    }, 0);

    return amount.toFixed(2);
  }, [orderDetails]);

  const returnTaxesTotal = useMemo(() => {
    const items = orderDetails.details.items;

    let taxAmount = 0;
    // items.forEach((item) => {
    //   if (item.tax_lines) {
    //     taxAmount = taxAmount + item.tax_lines[0]?.price;
    //   }
    // });

    return taxAmount;
  }, [orderDetails]);

  const returnTotalWithShipment = useMemo(() => {
    const items = orderDetails.details.items;

    let amount = items.reduce((acc, item) => {
      return acc + item.price;
    }, 0);

    amount =
      amount + orderDetails.details.offer.shippingCost + returnTaxesTotal;

    return amount.toFixed(2);
  }, [orderDetails, returnTaxesTotal]);

  return (
    <>
      <div class="Wrapper">
        <div class="left-section">
          <div class="header-thankyou">
            <svg width="45" height="45" viewBox="0 0 45 45">
              <path
                d="M19.2469 32.2485L34.6538 16.8416L32.2389 14.4268L19.2469 27.4188L12.7157 20.8876L10.3008 23.3024L19.2469 32.2485ZM22.5034 44.2712C19.4921 44.2712 16.6617 43.6998 14.0122 42.557C11.3626 41.4142 9.05796 39.8633 7.0982 37.9043C5.13845 35.9453 3.5868 33.6416 2.44325 30.9932C1.3001 28.3448 0.728516 25.5152 0.728516 22.5043C0.728516 19.4931 1.2999 16.6627 2.44268 14.0131C3.58546 11.3636 5.13635 9.05894 7.09534 7.09918C9.05433 5.13942 11.358 3.58777 14.0064 2.44423C16.6548 1.30107 19.4845 0.729492 22.4953 0.729492C25.5066 0.729492 28.337 1.30088 30.9865 2.44366C33.6361 3.58644 35.9407 5.13732 37.9005 7.09632C39.8603 9.05531 41.4119 11.359 42.5554 14.0074C43.6986 16.6558 44.2702 19.4854 44.2702 22.4963C44.2702 25.5076 43.6988 28.338 42.556 30.9875C41.4132 33.6371 39.8624 35.9417 37.9034 37.9015C35.9444 39.8612 33.6407 41.4129 30.9923 42.5564C28.3439 43.6996 25.5142 44.2712 22.5034 44.2712ZM22.4993 40.8337C27.6174 40.8337 31.9525 39.0576 35.5046 35.5055C39.0566 31.9535 40.8327 27.6184 40.8327 22.5003C40.8327 17.3823 39.0566 13.0472 35.5046 9.49512C31.9525 5.94303 27.6174 4.16699 22.4993 4.16699C17.3813 4.16699 13.0462 5.94303 9.49414 9.49512C5.94206 13.0472 4.16602 17.3823 4.16602 22.5003C4.16602 27.6184 5.94206 31.9535 9.49414 35.5055C13.0462 39.0576 17.3813 40.8337 22.4993 40.8337Z"
                fill="#333333"
              />
            </svg>
            <h1>
              <b>
                Thank you{" "}
                {`${orderDetails.details.customer.firstName} ${orderDetails.details.customer.lastName}`}
                !
              </b>
              <br />
              Your order is <b>confirmed</b>
            </h1>
          </div>
          <span>
            Please note, the charge will appear on your card as{" "}
            <strong>Nuzzle</strong>
          </span>
          <div class="info-box">
            <p>
              &#9733; You'll receive a confirmation email with your order
              details within <strong>20 minutes</strong>.
            </p>
            <p>
              &#9733; Your order will be shipped out within{" "}
              <strong>1 business day</strong> (weekends excluded).
            </p>
            <p>
              &#9733; As soon as it does, you will receive another email with
              the shipment and tracking details as well.
            </p>
            <p>
              &#9733; Delivery will take approximately{" "}
              <strong>7 business days</strong> (weekends excluded).
            </p>
            <p>
              We are always available for anything via email and phone:
              <br />
              <strong>
                Email Us:{" "}
                <a href="mailto:support@getnuzzle.com">support@getnuzzle.com</a>
              </strong>
              <br />
              <strong>
                Call Us: <a href="tel:+1800301-4043"> +1 (800) 301-4042</a>
              </strong>
            </p>
          </div>
          <div class="Customer">
            <h2>You just joined over 50,000 Happy Customers!</h2>
            <img src="images/customer.png" alt="Customer" />
            <p>
              Thank you for choosing Nuzzle to be a part of your sleeping
              adventure!
            </p>
            <p>
              Should you have any questions or need assistance, our team is here
              to help:
            </p>
            <br />
            <p>
              Contact us at{" "}
              <a href="mailto:support@getnuzzle.com">support@getnuzzle.com</a>
            </p>
            <br />
            <p>
              Best Regards,
              <br />
              Nuzzle Team
            </p>
          </div>
        </div>
        <div class="right-section">
          <div class="order-summary">
            <h2>
              Order Summary <span> ${returnTotalWithShipment}</span>
            </h2>
            <table>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
              {orderDetails.details.items.map((item, index) => (
                <tr>
                  <td>{item.title}</td>
                  <td>1</td>
                  <td>${item.price.toFixed(2)}</td>
                </tr>
              ))}
            </table>
            <div class="Details">
              <p>
                Sub Total:
                <span>${returnTotal}</span>
              </p>
              <p>
                Shipping:
                <span>
                  ${orderDetails.details.offer.shippingCost?.toFixed(2)}
                </span>
              </p>
              {/* <p>
                Taxes:
                <span>${returnTaxesTotal?.toFixed(2)}</span>
              </p> */}
              <p>
                Grand Total:
                <span>${returnTotalWithShipment}</span>
              </p>
            </div>
          </div>
          {/* <div class="shipping-info">
            <h2>Your Shipping Information</h2>
            <div>
              <p>Contact Information</p>
              <p>Payment Information</p>
              <p>Shipping Address</p>
              <p>Billing Address</p>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34213.537663267154!2d-74.02050098029393!3d40.71626227566731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1735660393631!5m2!1sen!2s"></iframe>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ThankYou;
