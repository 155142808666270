import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";
import mainStore from "store/store";
import {
  trackUpsellAccepted,
  trackPageViewEvent,
  trackPurchaseEvent,
} from "mixpanel/events";
import { useLocation } from "react-router-dom";
import PurchaseStore from "store/purchase";
import { calculateTax } from "utils/global";

function UpSell3A() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    allProductOffers,
    paymentIntent,
    setPaymentIntent,
    formattedTime,
    allPages,
    setAllPages,
    orderPlaced,
    selectedState,
  } = mainStore();

  const [loading, setLoading] = useState(false);
  const [loadingGLobal, setLoadingGlobal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState({});
  const { managePurchase } = PurchaseStore();

  useEffect(() => {
    initializeUpsell3A();
  }, []);

  useEffect(() => {
    if (window.hj) {
      window.hj("stateChange", location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (paymentIntent) {
      const pillowcases = allProductOffers.products.find((cases) => {
        return cases.sku === "NZ4001";
      });
      console.log("pillowcases ~ pillowcases:", pillowcases);

      if (pillowcases) {
        setSelectedDeal(pillowcases);
      }
    }
  }, [allProductOffers, paymentIntent]);

  const initializeUpsell3A = async () => {
    if (!paymentIntent) {
      navigate("/");
      return;
    }

    if (allPages.includes("up-sell-3a") || orderPlaced) {
      navigate("/thank-you");
      return;
    }

    const pages = [...allPages];
    pages.push("up-sell-3a");
    setAllPages(pages);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    trackPageViewEvent({ page_type: "Upsell 3A" });
  };

  const onAddPillow = async () => {
    const newPaymentIntent = { ...paymentIntent };
    newPaymentIntent.step = "step-upsell";
    newPaymentIntent.finalizePurchase = true;
    newPaymentIntent.eventDetails.url = window.location?.href;
    newPaymentIntent.details.items.push({
      title: selectedDeal.title,
      price: selectedDeal.afterDiscountValue,
      quantity: 1,
      sku: selectedDeal.sku,
      product_id: selectedDeal.productId,
      // tax_lines: calculateTax(selectedState, selectedDeal.afterDiscountValue),
    });

    setLoading(true);
    const res = await managePurchase(newPaymentIntent);

    if (res?.success) {
      trackUpsellAccepted(selectedDeal.sku);
      // trackPurchaseEvent(newPaymentIntent);
      setPaymentIntent(res.data);
      navigate("/thank-you");
      setLoading(false);
    } else {
      alert(res?.errorMessage);
      setLoading(false);
    }
  };

  const navigateToNextUpsell = async () => {
    if (loading) {
      return;
    }
    setLoadingGlobal(true);
    const newPaymentIntent = { ...paymentIntent };
    newPaymentIntent.step = "step-upsell";
    newPaymentIntent.finalizePurchase = true;
    newPaymentIntent.eventDetails.url = window.location?.href;
    const res = await managePurchase(newPaymentIntent);
    if (res?.success) {
      setPaymentIntent(res.data);
      // trackPurchaseEvent(newPaymentIntent);
      navigate("/thank-you");
    } else {
      alert(res?.errorMessage);
    }
    setLoadingGlobal(false);
    setLoading(false);
    // navigate("/up-sell-4a");
  };

  return (
    <>
      {loadingGLobal && (
        <div className="global-loader">
          <ClipLoader color="#fff" size={100} />
        </div>
      )}
      <div class="main-section main-section-upsell">
        <div class="small_container for-upsell w-container">
          <div class="warning_banner">
            <h5 class="sale-title">WAIT!</h5>
            <div id="warning" class="w-embed"></div>
          </div>
          <div class="secondary-heading_box">
            <div class="secondary-heading_text is-big">
              LAST CHANCE! ADD ONE MORE PILLOW TO YOUR ORDER WHILE STILL IN
              STOCK
            </div>
            {/* <div class="text-block-240">
              Add Another Nuzzle Pillow To Your Order
            </div> */}
          </div>
          <div class="limited-heading_box">
            <div class="sale-p">
              Get <span class="other_span">62% OFF</span>
            </div>
          </div>
          <div class="upsell_content">
            <div class="up_con-one">
              <div class="upsell-slider-wrapper">
                <div class="main-dots upsell-dots">
                  <div class="slide-dot">
                    <img
                      sizes="(max-width: 767px) 100vw, 384px"
                      srcset="
                      ../images/newro-p-500.webp   500w,
                      ../images/newro-p-800.webp   800w,
                      ../images/newro-p-1080.webp 1080w,
                      ../images/newro.webp        1500w
                    "
                      alt=""
                      src="../images/newro.webp"
                      loading="lazy"
                      class="slide-dot-iimg"
                    />
                    <img
                      src="../images/badge_new.webp"
                      loading="lazy"
                      sizes="(max-width: 479px) 110px, (max-width: 767px) 100vw, 110px"
                      srcset="
                      ../images/badge_new-p-500.webp 500w,
                      ../images/badge_new.webp       760w
                    "
                      alt=""
                      class="circle_badge-new"
                    />
                  </div>
                  <div class="slide-dot"></div>
                  <div class="slide-dot"></div>
                  <div class="slide-dot"></div>
                  <div class="slide-dot"></div>
                </div>
                <img
                  loading="lazy"
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f176839d9035414ca219f_prev.svg"
                  alt=""
                  class="prev-arr slick-arrow upsell-arr"
                />
                <img
                  loading="lazy"
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f17805bff702023229745_next.svg"
                  alt=""
                  class="next-arr slick-arrow upsell-arr"
                />
                <img
                  loading="lazy"
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/6270e07371b4bf03848279bc_best.png"
                  alt=""
                  class="best-slider-icon"
                />
              </div>
            </div>
            <div class="up_con-two">
              <div class="retail_only-box is-diff">
                <div class="before_price-text is-diff">
                  RETAIL PRICE{" "}
                  <span class="text-span-184">
                    ${selectedDeal?.retailValue?.toFixed()}
                  </span>
                  <br />
                </div>
                <div class="offer-price_box">
                  <div class="offer_price-text">
                    ONLY{" "}
                    <span class="text-span-183">
                      ${selectedDeal?.afterDiscountValue?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
              <div class="upsell_btn-box">
                <button
                  class="upsell_next-btn w-button"
                  style={{
                    backgroundColor: loading ? "#294a59" : "#29af5c",
                    boxShadow: loading
                      ? "0 3px 0 1px #294a59"
                      : "0 3px 0 1px #29af5c",
                  }}
                  disabled={loading}
                  onClick={() => onAddPillow()}
                >
                  {loading ? (
                    <span className="pay-now">
                      <i> ADDING TO ORDER</i>{" "}
                      <PulseLoader color="#ffffff" size={8} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      Add to Order <span class="text-span-186"></span>
                    </span>
                  )}
                </button>
              </div>
              <div class="discount_timer-box">
                <div class="discount_timer-text">
                  Your discount expires in{" "}
                  <span class="discount_timer-number">
                    {formattedTime} mins
                  </span>
                </div>
              </div>
              <div class="next_step-box">
                <a
                  class="next_step-text cursor-pointer"
                  onClick={() => navigateToNextUpsell()}
                >
                  No Thanks - I Don't Want This Discount
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpSell3A;
