// src/App.jsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "pages/home/index";
import Checkout from "pages/checkout/index";
import SubCheckout from "pages/checkout/details/sub-checkout";
import UpSell1A from "pages/upSells/upSell1A";
import UpSell1B from "pages/upSells/upSell1B";
import UpSell2A from "pages/upSellsNew/upsell2A";
import UpSell2B from "pages/upSellsNew/upSell2B";
import UpSell3A from "pages/upSellsNew/upSell3A";
import UpSell3B from "pages/upSellsNew/upSell3B";
import UpSell4A from "pages/upSells/upSell4A";
import ThankYou from "pages/thankyou";
import AboutUs from "pages/extras/about";
import TermsConditions from "pages/extras/termsConditions";
import ReturnPolicy from "pages/extras/returnPolicy";
import PrivacyPolicy from "pages/extras/privacyPolicy";
import ContactUs from "pages/extras/contactUs";
import App from "App";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/" element={<App />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/sub-checkout" element={<SubCheckout />} />
      <Route path="/up-sell-1a" element={<UpSell1A />} />
      <Route path="/up-sell-1b" element={<UpSell1B />} />
      <Route path="/up-sell-2a" element={<UpSell2A />} />
      <Route path="/up-sell-2b" element={<UpSell2B />} />
      <Route path="/up-sell-3a" element={<UpSell3A />} />
      <Route path="/up-sell-3b" element={<UpSell3B />} />
      <Route path="/up-sell-4a" element={<UpSell4A />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/return-policy" element={<ReturnPolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Routes>
  );
};

export default AppRoutes;
