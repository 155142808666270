import { create } from "zustand";
import { persist } from "zustand/middleware";
import axiosInstance from "axios-interceptor/axios";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${secs
    .toString()
    .padStart(2, "0")}`;
};

const mainStore = create(
  persist(
    (set) => ({
      allProductOffers: { offers: [], products: [] },
      requiredProducts: [
        "NZ1001",
        "NZ1002",
        "NZ1003",
        "NZ1004",
        "NZ1005",
        "NZ1006",
        "NZ2001",
        "NZ2002",
        "NZ2003",
        "NZ2004",
        "NZ2005",
        "NZ2006",
        "NZ4001",
        "NZ4002",
        "NZ5001",
        "NZ5002",
        "NZ5003",
        "NZ5004",
        "NZ5005",
        "NZ2001",
        "NZ2002",
        "NZ2003",
        "NZ2004",
        "NZ2005",
        "NZ2006",
        "NZ3001",
        "NZ3002",
        "NZ4001",
        "NZ4002",
        "NZ5001",
        "NZ9001",
        "NZ9002",
        "NZ1102",
        "NZ1101",
        "NZ1201",
      ],
      selectedState: "",
      connectionId: null,
      uniqueUserId: null,
      paymentIntent: null,
      meta: {
        states: [],
      },
      count: 15 * 60,
      isRunning: false,
      time: 15 * 60,
      formattedTime: formatTime(15 * 60),
      isRunningTimer: false,
      allPages: [],
      orderPlaced: false,
      taboolaClickId: null,
      voluumCid: null,
      utmParams: {},
      summaryBundle: {
        sku: "NZ1003",
        quantity: 0,
        label: "",
        retailValue: 0,
        afterDiscountValue: 0,
        shippingCost: 0,
        img: "",
      },
      upgradeToKing: false,
      selectedOffer: null,
      startTimer: () => {
        set({ isRunningTimer: true });
      },
      decrementTime: () => {
        set((state) => {
          if (state.time <= 0) {
            return { isRunningTimer: false }; // Stop timer when it reaches 0
          }
          const newTime = state.time - 1;
          return {
            time: newTime,
            formattedTime: formatTime(newTime),
          };
        });
      },
      startCountdown: () => {
        set((state) => {
          if (state.isRunning) return; // Prevent multiple countdowns

          const intervalId = setInterval(() => {
            set((state) => {
              if (state.count > 0) {
                return { count: state.count - 1 }; // Decrease the count
              } else {
                clearInterval(state.intervalId); // Stop when reaching 0
                return { isRunning: false, intervalId: null };
              }
            });
          }, 1000); // Countdown every second
          return { isRunning: true, intervalId };
        });
      },
      stopCountdown: () => {
        set((state) => {
          clearInterval(state.intervalId); // Clear interval to stop
          return { isRunning: false };
        });
      },
      getAllOffers: async () => {
        try {
          const res = await axiosInstance.get("/offers");
          if (res?.data) {
            return res.data;
          }
        } catch (error) {
          return null;
        }
      },

      getOrderDetails: async (id) => {
        try {
          const res = await axiosInstance.get(`/orders/${id}`);
          if (res?.data) {
            return res.data;
          }
        } catch (error) {
          return null;
        }
      },

      saveCustomerOrderDetails: async (data) => {
        try {
          const res = await axiosInstance.post(
            "/customers/saveCustomerDetails",
            data
          );
          if (res?.data) {
            return res.data;
          }
        } catch (error) {
          return null;
        }
      },

      getMeta: async () => {
        try {
          const res = await axiosInstance.get("/meta");
          console.log("getMeta: ~ res:", res);
          if (res?.data) {
            set({ meta: res.data });
          }
        } catch (error) {
          return null;
        }
      },

      setPaymentIntent: (intent) => {
        set({ paymentIntent: intent });
      },

      setUniqueUserId: (id) => {
        set({ uniqueUserId: id });
      },

      setProductOffers: (offers) => {
        set({ allProductOffers: offers });
      },

      setAllPages: (pages) => {
        set({ allPages: pages });
      },

      setOrderPlaced: (placed) => {
        set({ orderPlaced: placed });
      },

      setTaboolaClickId: (id) => {
        set({ taboolaClickId: id });
      },

      setVoluumCid: (id) => {
        set({ voluumCid: id });
      },

      setConnectionId: (id) => {
        set({ connectionId: id });
      },

      setUtmParams: (params) => {
        set({ utmParams: params });
      },
      setSummaryBundle: (params) => {
        set({ summaryBundle: params });
      },
      setUpgradeToKing: (params) => {
        set({ upgradeToKing: params });
      },
      setSelectedOffer: (params) => {
        set({ selectedOffer: params });
      },
      setSelectedState: (state) => {
        set({ selectedState: state });
      },
    }),
    {
      name: "summary-bundle-storage", // Key in localStorage
      partialize: (state) => ({ summaryBundle: state.summaryBundle }), // Persist only `summaryBundle`
    }
  )
);

export default mainStore;
