import React, { useEffect, useMemo, useRef, useState } from "react";
import ProductDetails from "./product-details";
import DetailsHeaader from "./detailsHeader";
import Paypal from "./paypal";
import Stripe from "./stripe";
import NMI from "./nmi";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import mainStore from "store/store";
import { trackCheckoutStartedEvent } from "mixpanel/events";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PurchaseStore from "store/purchase";
import Trust from "../trust";
import Reviews from "../review";
import Footer from "../footer";
import GlobalTimerStore from "store/globalTimer";
import moment from "moment";

function Details() {
  const {
    allProductOffers,
    paymentIntent,
    allPages,
    setAllPages,
    setPaymentIntent,
    setVoluumCid,
    summaryBundle,
    setSummaryBundle,
    upgradeToKing,
    setUpgradeToKing,
    selectedOffer,
    setSelectedOffer,
  } = mainStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { managePurchase } = PurchaseStore();
  const { hours, minutes, seconds } = GlobalTimerStore();
  const [bundleList, setBundleList] = useState([]);
  const [shippingInsurance, setShippingInsurance] = useState(true);
  const [selectedBundle, setSelectedBundle] = useState(summaryBundle.sku);
  const [loadingGLobal, setLoadingGlobal] = useState(false);
  const summaryRef = useRef(null);
  // const [summaryBundle, setSummaryBundle] = useState({
  //   sku: "",
  //   quantity: 0,
  //   label: "",
  //   retailValue: 0,
  //   afterDiscountValue: 0,
  //   shippingCost: 0,
  //   img: "",
  // });

  useEffect(() => {
    initializeCheckout();
  }, []);

  useEffect(() => {
    if (window.hj) {
      window.hj("stateChange", location.pathname);
    }
  }, [location.pathname]);

  const initializeCheckout = async () => {
    if (
      window.dtpCallback &&
      typeof window.dtpCallback.getClickID === "function"
    ) {
      const clickID = window.dtpCallback.getClickID();
      console.log("dpt Click ID:", clickID);
      setVoluumCid(clickID);
    } else {
      console.warn(
        "window.dtpCallback.getClickID is not defined or not a function"
      );
    }

    if (allPages.includes("checkout") && paymentIntent) {
      setLoadingGlobal(true);
      const newPaymentIntent = { ...paymentIntent };
      newPaymentIntent.finalizePurchase = true;
      const res = await managePurchase(newPaymentIntent);
      if (res?.success) {
        setPaymentIntent(res.data);
        navigate("/thank-you");
      }
      setLoadingGlobal(false);
    }
    setLoadingGlobal(false);

    const pages = [...allPages];
    pages.push("checkout");
    setAllPages(pages);

    trackCheckoutStartedEvent();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {
    const defaultBundle = allProductOffers.offers.find((offer) => {
      return offer.sku === selectedBundle;
    });
    if (allProductOffers.offers && defaultBundle) {
      setSummaryBundle(defaultBundle);
    }
    setBundleList(allProductOffers.offers);
  }, [allProductOffers]);

  useEffect(() => {
    const bundle = bundleList.find((row) => {
      return row.sku === selectedBundle;
    });

    if (bundle) {
      console.log("useEffect ~ bundle:", bundle);
      setSummaryBundle(bundle);
    }
  }, [selectedBundle]);

  useEffect(() => {
    const bundle = bundleList.find((row) => {
      return row.sku === selectedBundle;
    });

    if (bundle) {
      const prevBundle = filteredOffers.find((offer) => {
        return offer.quantity === bundle.quantity;
      });
      setSelectedBundle(prevBundle.sku);
    }
  }, [upgradeToKing]);

  const returnTotalIfShippingIncluded = useMemo(() => {
    return summaryBundle.afterDiscountValue;
  }, [summaryBundle, upgradeToKing]);

  const filteredOffers = useMemo(
    () =>
      bundleList.filter((bundle) => {
        return upgradeToKing
          ? ["NZ1004", "NZ1005", "NZ1006"].includes(bundle.sku)
          : ["NZ1001", "NZ1002", "NZ1003"].includes(bundle.sku);
      }),
    [bundleList, upgradeToKing]
  );

  const returnSaveTotal = (bundle) => {
    return bundle.retailValue - bundle.afterDiscountValue;
  };

  const returnSaveTotalSummay = (bundle) => {
    return bundle.retailValue - bundle.afterDiscountValue;
  };

  const handleOptionChange = (event) => {
    // console.log("handleOptionChange ~ event:", event);
    // console.log("deal ~ bundleList:", bundleList);
    // const deal = bundleList.find((bundle) => {
    //   return bundle.type === event.type;
    // });
    setSelectedBundle(event.sku);
  };

  const handleCheckboxChange = (event) => {
    setUpgradeToKing(!upgradeToKing);
  };

  const handleScroll = () => {
    // summaryRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const addBusinessDays = (startDate, daysToAdd) => {
    let currentDate = moment(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.add(1, "days");
      if (currentDate.isoWeekday() <= 5) {
        addedDays++;
      }
    }
    return currentDate;
  };

  const deliverDate = addBusinessDays(moment(), 1).format("ddd, MMM Do");

  const proceedToCheckout = () => {
    // console.log("selectedBundle", selectedBundle);
    // if (!selectedBundle || (selectedBundle && selectedBundle === "")) {
    //   alert("Please select any offer first");
    //   return;
    // }
    navigate("/sub-checkout");
  };

  return (
    <>
      {loadingGLobal && (
        <div className="global-loader">
          <ClipLoader color="#fff" size={100} />
        </div>
      )}
      <div class="checkout-content">
        <DetailsHeaader />
        <div class="w-layout-blockcontainer con_small w-container">
          <div class="checkout-container">
            <div class="rtc-checkout-3 gr1 ds1 dw1 extra-changes">
              <div class="main-checkout-box newbox">
                <ProductDetails />
                <div class="new_checkout_div newstyle">
                  <div class="new_first newup">
                    <div class="div-block-595 leftnew changestick">
                      <div class="new-selection-div cop1">
                        <div class="sub-tab">
                          <div class="tabs-2 price-column w-tabs">
                            <div
                              class="tabs-menu-2 w-tab-menu"
                              onClick={handleScroll}
                            >
                              {filteredOffers.map((bundle, index) => (
                                <div>
                                  <a
                                    class={`checkout-select-variant-2 _2_bottles nuzzle2 gonew zx2 w-inline-block w-tab-link ${
                                      selectedBundle === bundle.sku
                                        ? "w--current"
                                        : ""
                                    }`}
                                    key={index}
                                  >
                                    <div
                                      data-scroll-time="1.25"
                                      class="select-box-div top1 ff1 da1"
                                      onClick={() => handleOptionChange(bundle)}
                                    >
                                      <div class="checkbox-radio cb-sq tt1 ju1 pr1">
                                        <div class="check-circle-new sq1 chc1">
                                          {selectedBundle === bundle.sku && (
                                            <img
                                              loading="lazy"
                                              src="../images/checkmark-cards.svg"
                                              alt=""
                                              class="check-white-6"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div class="sel-top-box aa1 uy1">
                                        <div class="pro-tt-div in-per">
                                          <div
                                            class={`tt-pro-txt ${
                                              index === 0 ? "ml-7" : ""
                                            }`}
                                          >
                                            {" "}
                                            {bundle.quantity} Pillow
                                            {bundle.quantity > 1 && (
                                              <span>s</span>
                                            )}
                                          </div>
                                          <div class="save-below-text">
                                            {bundle.label}
                                          </div>
                                        </div>
                                        {bundle.shippingCost === 0 && (
                                          <div class="ff-div-cen">
                                            <div class="ff-txt">
                                              Free Shipping
                                            </div>
                                          </div>
                                        )}
                                        <div class="sel-left-div q1">
                                          <div class="pre-div"></div>
                                          <div class="sel-bot-inner gy1 fd1 gh1">
                                            <div class="sbi-left">
                                              <div class="re-tol-div bv1">
                                                <p class="bb-pri">
                                                  Retail $
                                                  {bundle.retailValue?.toFixed(
                                                    2
                                                  )}
                                                </p>
                                              </div>
                                              <div class="un-pp">
                                                <span class="unit-price-new">
                                                  $
                                                  {(
                                                    bundle.afterDiscountValue /
                                                    bundle.quantity
                                                  ).toFixed(2)}
                                                  {bundle.shippingCost === 0 &&
                                                    "/ea"}
                                                </span>
                                                {bundle.shippingCost > 0 && (
                                                  <span class="sh-txt-new">
                                                    +$
                                                    {bundle.shippingCost?.toFixed(
                                                      2
                                                    )}{" "}
                                                    S/H
                                                  </span>
                                                )}
                                              </div>
                                              <div class="save-on-total-text">
                                                (Save $
                                                {returnSaveTotal(
                                                  bundle
                                                ).toFixed(2)}{" "}
                                                Total)
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {index === 0 && (
                                          <img
                                            loading="lazy"
                                            src="../images/ds1.png"
                                            alt=""
                                            class="pp-badge"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div class="best-seller-inner">
                            <div class="skeleton-load"></div>
                            <div class="pricing-container"></div>
                          </div>
                        </div>
                      </div>
                      <div class="upgrade_box">
                        <div class="uw_left">
                          <div class="uw_one">
                            <img
                              width="94"
                              sizes="(max-width: 1279px) 94px, (max-width: 1439px) 7vw, 94px"
                              alt=""
                              src="../images/nuz1.png"
                              loading="lazy"
                              srcset="
                                ../images/nuz1-p-500.png 500w,
                                ../images/nuz1-p-800.png 800w,
                                ../images/nuz1.png       883w
                              "
                              class="uw_upgrade-product"
                            />
                          </div>
                          <div class="uw_two">
                            <div class="up_box-title">Upgrade to King Size</div>
                            <div class="up_box-subtitle">
                              Only $9.99 Extra per Pillow
                            </div>
                          </div>
                        </div>
                        <div class="uw_right">
                          <div class="uw_three">
                            <button
                              class={`sel_upgrade-btn w-inline-block ${
                                upgradeToKing ? " upgrade-active" : ""
                              }`}
                              onClick={handleCheckboxChange}
                            >
                              <img
                                loading="lazy"
                                src="../images/up-arrow-btn.svg"
                                alt=""
                                class="uw_btn-icon"
                              />
                              <div class="uw_btn-text">
                                {upgradeToKing ? "UPGRADED" : "UPGRADE"}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="insurance_box">
                        <div class="insurance_image-box">
                          <div class="insurance_icon-box">
                            <img
                              src="../images/ss-box-cropped.svg"
                              loading="lazy"
                              alt=""
                              class="image-136"
                            />
                            <div class="text-block-235">
                              Shipping
                              <br />
                              Insurance
                            </div>
                          </div>
                        </div>
                        <div class="insurance_text-block">
                          <h6 class="insurance_heading">Shipping Insurance</h6>
                          <div class="insurance_text">
                            Protect your order from damage, loss, theft for just
                            $3.99! Get peace of mind with Nuzzle&#x27;s shipping
                            insurance - if your delivery is damaged, lost, or
                            stolen during transit, we&#x27;ll replace it at no
                            cost to you.
                          </div>
                        </div>
                        <div className="insurance-checkbox">
                          <input
                            type="checkbox"
                            checked={shippingInsurance}
                            onChange={() =>
                              setShippingInsurance(!shippingInsurance)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="selection_cta-box">
            <div class="ck_new-badge-block">
              <div class="co_left-block">
                <img
                  loading="lazy"
                  src="../images/small-fire.svg"
                  alt=""
                  class="fire-badge-icon"
                />
                <div class="pp-left-badge">
                  <strong class="pp-left-badge-bold">Hurry! 126 people</strong>{" "}
                  are on checkout
                </div>
              </div>
              <div class="co_right-block">
                <img
                  loading="lazy"
                  src="../images/box-fast.svg"
                  alt=""
                  class="fast-box-icon"
                />
                <div class="pp-right-badge">Ships by </div>
                <div class="pp-right-badge-date">{deliverDate}</div>
              </div>
            </div>
            <button
              class="proceed-ck-btn w-inline-block"
              onClick={() => proceedToCheckout()}
            >
              <div class="ck-countdown-new-text">
                Sale Ends In{" "}
                <span id="mobile_timer">
                  {hours}:{minutes}:{seconds}
                </span>
              </div>
              <div class="ck_new-btn-text">Proceed to Checkout</div>
            </button>
          </div>
        </div>
      </div>
      {/* <Reviews />
      <Footer /> */}
    </>
  );
}

export default Details;
