import React from "react";
import moment from "moment";

function ProductDetails() {
  const addBusinessDays = (startDate, daysToAdd) => {
    let currentDate = moment(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.add(1, "days");
      if (currentDate.isoWeekday() <= 5) {
        addedDays++;
      }
    }
    return currentDate;
  };

  const deliverDate = addBusinessDays(moment(), 1).format("dddd, MMM Do");

  return (
    <div>
      <div>
        <div class="b1-d ds12">
          <div class="cen-div-img">
            <img
              src="../images/can-post-img.svg"
              loading="lazy"
              alt=""
              class="re1-img"
            />
          </div>
          <div class="div-block-680">
            <div>
              <div class="div-block-609">
                <img
                  src="../images/Green-circle.svg"
                  loading="lazy"
                  alt=""
                  class="circle-green"
                />
                <div class="w-embed"></div>
              </div>
            </div>
            <div>
              <div class="ris-tt">READY TO SHIP</div>
            </div>
          </div>
          <div class="sa1-t">
            <span class="date-21">Ships by {deliverDate}</span> USPS order
            within <span class="time-order-within">3 hrs 6 mins</span>
          </div>
        </div>
        <div id="order_summary_div" class="rem-div">
          <div class="rem-txt">
            <span class="text-span-139">Order now</span>.. only{" "}
            <span class="text-span-138">23</span> left in stock
          </div>
        </div>
        <div class="bnb-div">
          <div class="div-block-683"></div>
          <div class="div-block-685">
            <div class="bnb-txt">BUNDLE &amp; SAVE</div>
          </div>
          <div class="div-block-684"></div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
